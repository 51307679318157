<template>
  <div>
    <h1>自动回复列表</h1>
    <el-form inline @submit.native.prevent>
      <el-form-item>
        <el-button type="success" class="addReply" @click="$router.push(`/autoreplys/create`)">添加回复</el-button>
      </el-form-item>
      <el-form-item>
        <el-input type="search" v-model="query.seach_word" @keydown.enter.native="seach()" placeholder="搜索关键词/规则名称">
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="seach()">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="items">
      <el-table-column prop="name" label="规则名称"></el-table-column>
      <el-table-column prop="keywords" label="关键词">
        <template slot-scope="scope">
          <span v-for="(word, index) in scope.row.keywords" :key="index">
            {{ word.keyword }}
            <!-- 只有在不是最后一个关键词时才显示逗号 -->
            <span v-if="index !== scope.row.keywords.length - 1">,</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="150">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="$router.push(`/autoreplys/edit/${scope.row._id}`)">编辑</el-button>
          <el-button type="text" size="small" @click="remove(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <span class="demonstration"></span>
    <el-pagination @size-change="fetch()" @current-change="fetch()" background :current-page.sync="query.page"
      :page-sizes="[10, 20, 30, 50]" :page-size.sync="query.perPage" layout="total,sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>

<style>
.addReply {
  margin-right: 30px;
  margin-left: 10px;
}
</style>

<script>
export default {
  data() {
    return {
      items: [],
      keywords: [
        {
          keyword: '',
          match: ''
        }
      ],
      total: 0,
      query: {
        page: 1,
        perPage: 10,
        seach_word: '',
      }
    }
  },
  methods: {
    seach() {
      this.query.page = 1
      this.fetch()
    },
    async fetch() {
      const res = await this.$http.get('rest/autoreplys', { params: this.query })
      this.items = res.data.items
      this.total = res.data.total
    },
    async remove(row) {
      this.$confirm(`确定要删除规则"${row.name}"吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$http.delete(`rest/autoreplys/${row._id}`)
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
        this.fetch()
      })
    }
  },
  created() {
    this.fetch()
  }
}
</script>
<template>
  <div>
    <h1>管理员列表</h1>
    <el-table :data="items">
      <el-table-column prop="name" label="用户名"></el-table-column>
      <el-table-column prop="username" label="账号"></el-table-column>
      <el-table-column prop="unionid" label="UnionID"></el-table-column>
      <el-table-column fixed="right" label="操作" width="150">
      <template slot-scope="scope">
        <el-button type="text" size="small" 
        @click="$router.push(`/admin_users/edit/${scope.row._id}`)">编辑</el-button>
        <el-button type="text" size="small" 
        @click="remove(scope.row)">删除</el-button>
      </template>
    </el-table-column>
    </el-table>

    <span class="demonstration"></span>
    <el-pagination
      @size-change="fetch()"
      @current-change="fetch()"
      background
      :current-page.sync="query.page"
      :page-sizes="[10, 20, 30, 50]"
      :page-size.sync="query.perPage"
      layout="total,sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>

  </div>
</template>

<script>
export default {
  data(){
    return{
      items: [],
      total: 0,
      query:{
        page: 1,
        perPage:10,
        seach_word:'',
      }
    }
  },
  methods:{
    async fetch(){
      const res = await this.$http.get('rest/admin_users',{params:this.query})
      this.items = res.data.items
      this.total = res.data.total
    },
    async remove(row){
      this.$confirm(`确定要删除"${row.name}"吗？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          const res = await this.$http.delete(`rest/admin_users/${row._id}`)
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.fetch()
        })
    }
  },
  created(){
    this.fetch()
  }
}
</script>
<template>
  <el-container  class="container" style="height: 100vh;">
    <el-header class="header-container">
      <router-link to="/">
        <div class="logo">
          <img src="../assets/iKPOPLogoMini.png" alt="Company Logo">
        </div>
      </router-link>

      <el-menu mode="horizontal" router :default-active="$route.path" text-color="#333" active-text-color="#409EFF"
        class="custom-menu">
        <!-- <el-menu-item class="custom-menu-item" index="/" @click="refresh">主页</el-menu-item> -->
        <el-menu-item class="custom-menu-item" index="/listens/list" @click="refresh">我的音乐</el-menu-item>
        <el-menu-item class="custom-menu-item" index="/my/profile" @click="refresh">个人中心</el-menu-item>
      </el-menu>
      <div class="header-personal">
        <el-dropdown style="text-align: right; font-size: 16px" class="el-dropdown-link">
          <i>
            <img v-if="headimgurl" :src="headimgurl" alt="avatar" class="avatar-icon">
          </i>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="usercenter">个人中心</el-dropdown-item>
            <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>
    <el-container>
      <el-main>
        <div v-if="$route.path === '/'" style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
          <h3 style="align-items: center;">欢迎 {{ loggedInUsername }} 来到 · iKPOP 网站 </h3>
          <template>
            <div class="text-wrapper">
            <div class="text">
              很开心能在这里遇见你, 今天起 iKPOP · 网站 正式开启试运营。
            </div>
            <div class="text">
              <span>今年4月份， 小程序接连收到恶意投诉，导致复制功能丢失。尽管我们努力找了替代的方法，如 发送到公众号、发送到邮箱。但是实际使用起来，我也知道确实还是很不方便的。</span>
            </div>
            <div class="text">
              <span>所以，努力开发搭建了这个网站，希望可以方便大家使用。</span>
            </div>
            <div class="text">
              <span class="text1">目前，网站仅作为接收小程序获取到的链接。</span>
            </div>
            <div class="text">
              <span class="text1">为了确保链接安全， 暂时设置了有效期为7天。</span>
            </div>
            <div class="text">
              <span>简单的说，我们在小程序获取到链接，会自动发送到此网站，在【我的音乐】页面就可以看到已获取到的链接。</span>
            </div>
            <div class="text">
              <span>网站暂时无法搜索音源， 但是我们会逐步完善。计划以后可以直接在网站上获取音源，并且我们计划也将会包括专辑。只是还需一些时间，还请耐心等待。</span>
            </div>
            <div class="text">
              <span>希望我们的努力， 能得到大家的认可与喜欢， 感谢大家的支持！</span>
            </div>
          </div>
          </template>
        </div>
        <router-view></router-view>
      </el-main>
    </el-container>

    <!-- 移动端 底部导航栏 -->
    <el-footer class="footer-menu" v-show="isMobile">
      <el-menu mode="horizontal" class="custom-menu2" router :default-active="$route.path" text-color="#333"
        active-text-color="#409EFF" size="small">
        <!-- <el-menu-item class="custom-menu-item" index="/" @click="refresh">主页</el-menu-item> -->
        <el-menu-item class="custom-menu-item" index="/listens/list" @click="refresh">我的音乐</el-menu-item>
        <el-menu-item class="custom-menu-item" index="/my/profile" @click="refresh">个人中心</el-menu-item>
      </el-menu>
    </el-footer>
  </el-container>
</template>


<script>
import router from '@/router';

export default {
  data() {
    return {
      loggedInUsername: null, // 假设这个变量保存已登录用户的用户名
      headimgurl: null,
      isMobile: false,
    };
  },
  components: { router },
  methods: {
    async fetchUserInfo() {
      try {
        const token = localStorage.getItem('token');
        const response = await this.$http.get('/userinfo', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        this.userInfo = response.data.user;
        this.loggedInUsername = this.userInfo.nickname
        this.headimgurl = this.userInfo.headimgurl
        // console.log('2', this.userInfo.nickname)
      } catch (error) {
        if (error.response && error.response.status !== 401) {
          this.$message({
            type: 'error',
            message: '获取用户信息失败'
          });
        }
      }
    },
    logout() {
      // 清除本地存储中的令牌
      localStorage.removeItem('token');
      // 跳转到登录页
      this.$router.push('/login');
      this.$message({
        type: 'success',
        message: '退出登录成功',
      });
    },
    usercenter() {
      this.$router.push('/my/profile');
      // location.reload(); // 刷新页面
    },
    refresh() {
      this.fetchUserInfo()
    },
    checkIsMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
  },
  created() {
    this.refresh();
    this.checkIsMobile();
    window.addEventListener('resize', this.checkIsMobile);
  },
  mounted() {
    // 当组件挂载时获取已登录用户的用户名
    // this.fetchLoggedInUsername();
    this.refresh()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkIsMobile);
  }
};
</script>

<style>
.container{
background-color: #f6f6f6;
}

.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  /* margin-right: 20px; */
}

.logo img {
  height: 50px;
  width: auto;
}

.header-title {
  text-align: center;
  font-size: 18px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  background-image: linear-gradient(to top, #c471f5 0%, #fa71cd 100%);
  -webkit-background-clip: text;
  background-clip: text;
  /* 添加标准的 background-clip 属性 */
  /* -webkit-text-fill-color: transparent; */
}

.header-content {
  display: flex;
  align-items: center;
}

.el-header {
  /* background-image: linear-gradient(to top, #BFFFFF80 0%, #ffffff 70%); */
  background-color: #ffffff;
  color: #333;
  line-height: 60px;
}

.custom-menu {
  /* background-image: linear-gradient(to top, #BFFFFF80 0%, #ffffff 70%) !important; */
  background-color: #ffffff;
  margin: 0px 30px;
}

.custom-menu-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
}

.header-personal {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100px;
}


.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}

.avatar-icon {
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  /* margin-right: 20px; */
  border-radius: 100%;
  width: 40px;
  height: 40px;
  border: 2px solid #ffffff;
  /* 设置边框颜色和宽度 */
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); 可选：添加阴影效果 */
}



.username {
  font-size: 20px;
  font-weight: bold;
  background: linear-gradient(to right, #d6ddfa, #6d85f3);
  -webkit-background-clip: text;
  background-clip: text;
  /* 添加标准的 background-clip 属性 */
  -webkit-text-fill-color: transparent;
}

/* .logout-btn {
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.el-main {
  padding: 20px;
}

.el-aside {
  /* background-image: linear-gradient(to bottom, #BFFFFF80 0%, #ffffff 70%); */
  color: #333;
}

.footer-menu {
  display: none;
}


.tooltip-text {
  white-space: pre-wrap;
  
  /* 保留空白符，允许换行 */
  /* max-width: 200px; */
  /* 设置最大宽度 */
}

.text{
  font-size: 15px;
  margin: 5px;
  letter-spacing: 1px;
  line-height:25px;
}

.text1{
  font-weight: 600;
  color: #409EFF;
}



/* 移动端样式 */
@media (max-width: 768px) {
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /* .header-title {
    margin-bottom: 10px;
  } */

  /* .header-content {
    margin-top: 10px;
    flex-direction: column;
    align-items: center;
  } */

  .header-personal {
    display: flex;
    justify-content: right;
    align-items: center;
  }


  /* .avatar-icon {
    margin-right: 0;
    margin-bottom: 10px;
  } */

  /* .logout-btn {
    width: 100%;
    justify-content: center;
  } */

  .footer-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-image: linear-gradient(to bottom, #BFFFFF80 0%, #ffffff 70%) !important; */
    /* background-color: #ffffff; */
    margin-bottom: 20px;
  }

  /* .custom-menu2 {
    background-image: linear-gradient(to bottom, #BFFFFF80 0%, #ffffff 70%) !important;
    background-color: #ffffff;

  } */

  .custom-menu {
    display: none;
  }
}
</style>

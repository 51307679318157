<template>
  <div class="about">
    <h1>{{id ? '编辑' : '添加'}}管理员</h1>
    <el-form label-width="120px" @submit.native.prevent="save">
      <el-form-item label="用户名" >
        <el-input v-model="model.name"></el-input>
      </el-form-item>
      <el-form-item label="账号" >
        <el-input v-model="model.username"></el-input>
      </el-form-item>
      <el-form-item label="密码" >
        <el-input v-model="model.password"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" native-type="submit">保存</el-button>
        <el-button type="danger"  @click="cancel">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>


<style>
  .about{
    margin: 0px 100px;    
  }
  
  .guanjianci{
    display: flex;
    flex-direction: row;
    justify-content: left;
  }
  .keyword {
    margin: 20px 0px;
    
  } 
  .matchs {
    width: 120px;
    padding-left: 20px;
    padding-right: 10px;
  } 
  .words {
    width:500px;
    padding:0em 2em;
  }
</style>


<script>
import { VueEditor } from "vue2-editor";

export default{
  props: {
    id: {}
  },
  components: {
    VueEditor
  },
  data(){
    return{
      model:{},
    }
  },
  methods:{
    async save(){
      let res
      if (this.id) {
        res = await this.$http.put(`rest/admin_users/${this.id}`,this.model)
      } else{
        res = await this.$http.post('rest/admin_users',this.model)
      }
      this.$router.push('/admin_users/list')
      this.$message({
        type: 'success',
        message: '保存成功'
      })
    },
    async fetch(){
      const res = await this.$http.get(`rest/admin_users/${this.id}`)
      this.model = res.data
    },
    async cancel(){
      this.$router.go(-1)
    },
  },
  created(){
    this.id && this.fetch()
  }
}

</script>

<template>
  <div class="profile">
    <div class="userinfo">
      <el-row class="avatar">
        <div class="block"><el-avatar :size="90" :src="userInfo.headimgurl"></el-avatar></div>
      </el-row>
      <el-row class="nickname">
        <span>{{ userInfo.nickname }}</span>
      </el-row>
    </div>
    <!-- 水平分割线 -->
    <el-divider></el-divider>
    <div class="music">
    <el-row class="song">
      <span class="number">{{ totalsong }}</span>
      <span>歌曲</span>
    </el-row>
    <!-- 垂直分割线 -->
    <el-divider direction="vertical"></el-divider> 
    <el-row class="album">
      <span class="number">{{ totalalbum }}</span>
      <span>专辑</span>
    </el-row>
  </div>

    <!-- 可选：增加一个返回按钮 -->
    <!-- <el-row class="button-group">
      <el-button @click="cancel" type="primary">返回</el-button>
    </el-row> -->
  </div>
</template>

<style scoped>
.profile {
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.userinfo{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nickname{
  align-items: center;
  text-align: center;
  margin: 10px 0px;
  font-size: 25px;
  font-weight: 500;
}

.music{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}


.song,
.album {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.number{
  margin-bottom: 5px;
}


</style>

<script>
export default {
  props: {
    id: {}
  },
  data() {
    return {
      totalsong: '0',
      totalalbum: '0',
      userInfo: {
        unionid: '',
        nickname: '',
        headimgurl: '',
        createdAt: '',
      }
    }
  },
  methods: {
    // async fetch() {
    //   const res = await this.$http.get(`/user_profile/`)
    //   this.model = res.data
    // },
    async fetchUserInfo() {
      try {
        const token = localStorage.getItem('token');
        const response = await this.$http.get('/userinfo', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        this.userInfo = response.data.user;
        // console.log('2', this.userInfo.nickname)
      } catch (error) {
        if (error.response && error.response.status !== 401) {
            this.$message({
                type: 'error',
                message: '获取用户信息失败'
            });
        }
      }
    },
    async fetch() {
      const res = await this.$http.get('/iklistenlist')
      // this.items = res.data
      // console.log(res.data)
      this.totalsong = res.data.total
    },
  },
  created() {
    this.fetch()
    this.fetchUserInfo()
  },
  filters: {
    formatDate(date) {
      if (!date) return '';
      const d = new Date(date);
      const year = d.getFullYear();
      const month = (`0${d.getMonth() + 1}`).slice(-2);
      const day = (`0${d.getDate()}`).slice(-2);
      const hours = (`0${d.getHours()}`).slice(-2);
      const minutes = (`0${d.getMinutes()}`).slice(-2);
      const seconds = (`0${d.getSeconds()}`).slice(-2);
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
  },
}
</script>

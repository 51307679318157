<template>
  <div>微信登录成功，正在处理登录...</div>
</template>

<script>
export default {
  created() {
    this.checkWechatLoginSuccess();
  },
  methods: {
    // checkWechatLoginSuccess() {
    //   const hash = window.location.hash;
    //   const queryString = hash.substring(hash.indexOf('?') + 1);
    //   const urlParams = new URLSearchParams(queryString);
    //   const token = urlParams.get('token');
    //   const nickname = urlParams.get('nickname');

    //   if (token) {
    //     // console.log('微信登录成功', token, nickname);

    //     // 通过 postMessage 将 token 和 nickname 发送回主窗口
    //     window.opener.postMessage({ token, nickname }, '*');

    //     // 关闭当前窗口
    //     window.close();
    //   }
    // },


    checkWechatLoginSuccess() {
      const hash = window.location.hash;
      const queryString = hash.substring(hash.indexOf('?') + 1);
      const urlParams = new URLSearchParams(queryString);
      const token = urlParams.get('token');
      const nickname = urlParams.get('nickname');
      const platform = urlParams.get('platform')

      // console.log(platform)

      if (token) {
        // 通过 postMessage 将 token 和 nickname 发送回主窗口
        if (window.opener && platform === 'openPlatform') {
          window.opener.postMessage({ token, nickname }, window.opener.location.origin);

          // 关闭当前窗口
          window.close();
        } else {
          if (platform === 'publicAccount') {
            localStorage.setItem('token', token);
            // 跳转到主页面
            this.$router.push('/');
          }
        }

      } else {
        console.error('登录信息缺失');
        this.$message({
          type: 'error',
          message: '登录失败',
          // offset: 100, // 这里设置消息提示框距离页面顶部 100 像素
          duration: 5000 // 设置消息提示显示时间为 5000 毫秒（5 秒）
        });
        // 处理登录信息缺失的情况，例如显示错误提示
      }
    }

    // handleLoginSuccess(token, nickname) {
    //   // console.log('到这里了');
    //   // 保存 token 到本地存储或 Vuex
    //   localStorage.setItem('token', token);
    //   // 跳转到主页面
    //   this.$router.push('/');
    // }
  }
};
</script>

<template>
  <div class="notfound-content">
    <span class="notfound-title">没有找到相关内容</span>
    <el-row class="notfound-tips">
    <span>即将回到</span> 
    <el-link type="success" @click="redirectToHome" class="tips-link">我的音乐</el-link>
    <span>( {{ countdownText }} )</span>
  </el-row>
  </div>
</template>

<style scoped>
.notfound-content {
  margin: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.notfound-title{
  font-size: 38px;
  color: #000;
  margin: 20px;
}

.notfound-tips{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #666666;
  font-size: 18px;
}

.tips-link{
  margin: 0px 6px;
  font-size: 18px;
}


</style>

<script>
export default {
  data() {
    return {
      redirectTimer: null,
      elapsedSeconds: 0,
      countdownDuration: 5 // 设置倒计时时长为5秒
    }
  },
  computed: {
    countdownText() {
      const remainingSeconds = Math.max(0, this.countdownDuration - this.elapsedSeconds);
      const minutes = Math.floor(remainingSeconds / 60);
      const seconds = remainingSeconds % 60;
      return `${seconds}`;
    }
  },
  methods: {
    redirectToHome() {
      this.$router.replace('/listens/list');
    },
    updateCountdown() {
      this.elapsedSeconds += 1;
      if (this.elapsedSeconds >= this.countdownDuration) {
        this.redirectToHome();
      }
    }
  },
  created() {
    this.redirectTimer = setInterval(this.updateCountdown, 1000);
  },
  beforeDestroy() {
    clearInterval(this.redirectTimer);
  }
}
</script>

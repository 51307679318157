<template>
  <div class="login-container">
    <div class="background"></div>

    <!-- PC端 -->
    <el-card class="login-card" v-if="!isMobile">
      <el-row class="login-header">
        <div class="logo">
          <img src="../assets/iKPOPLogoMini.png" alt="Company Logo">
        </div>
        <div class="login-title">
          <h3 class="title-welcom">·</h3>
          <h3 class="title-welcom">登录</h3>
        </div>
      </el-row>
      <!-- <el-form @submit.native.prevent="login" class="login-form">
        <el-form-item label="账号 :" class="form-item">
          <el-input v-model="model.username" placeholder="请输入账号" clearable></el-input>
        </el-form-item>
        <el-form-item label="密码 :" class="form-item">
          <el-input type="password" v-model="model.password" placeholder="请输入密码" clearable></el-input>
        </el-form-item>
        <el-form-item class="form-item-login">
          <el-button type="primary" native-type="submit" class="login-button">登录</el-button>
        </el-form-item>
      </el-form> -->
      <el-form class="login-form">
        <el-form-item class="form-item-login">
          <el-button type="success" @click="loginWithWechat" class="login-button">微信扫码登录</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <!-- 移动端 -->
    <el-card class="login-card" v-if="isMobile">
      <el-row class="login-header">
        <div class="logo">
          <img src="../assets/iKPOPLogoMini.png" alt="Company Logo">
        </div>
        <div class="login-title">
          <h3 class="title-welcom">·</h3>
          <h3 class="title-welcom">登录</h3>
        </div>
      </el-row>
      <el-form @submit.native.prevent="login" class="login-form">
        <!-- <el-form-item label="账号 :" class="form-item">
          <el-input v-model="model.username" placeholder="请输入账号" clearable></el-input>
        </el-form-item>
        <el-form-item label="密码 :" class="form-item">
          <el-input type="password" v-model="model.password" placeholder="请输入密码" clearable></el-input>
        </el-form-item> -->
        <el-form-item class="form-item-login">
          <!-- <el-button type="primary" native-type="submit" class="login-button">登录</el-button> -->
          <!-- <el-button type="success" @click="loginWithWechat" class="login-button">微信登录</el-button> -->
          <el-button type="success" @click="redirectToWechatLogin" class="login-button">微信授权登录</el-button>
        </el-form-item>
      </el-form>

    </el-card>

  </div>
</template>

<script>

export default {
  data() {
    return {
      model: {},
      wechatLoginUrl: '', // 保存微信登录URL
      qrCodeUrl: '', // 用于显示二维码图片的URL
      loginTimer: null, // 用于轮询登录状态的计时器
      isMobile: false,
    };
  },
  // created() {
  //   this.checkWechatLoginSuccess();
  // },
  methods: {
    // async login() {
    //   // 原始的账号密码登录逻辑
    //   const res = await this.$http.post('login', this.model);
    //   // console.log('Hello, Jiandie!');
    //   localStorage.setItem('token', res.data.token);
    //   this.$router.push('/');
    //   this.$message({
    //     type: 'success',
    //     message: '登录成功',
    //     offset: 100, // 这里设置消息提示框距离页面顶部 100 像素
    //     duration: 2000 // 设置消息提示显示时间为 5000 毫秒（5 秒）
    //   });
    // },

    async redirectToWechatLogin() {
      try {
        const res = await this.$http.get('wechat-login-mobile');
        const wechatAuthUrl = res.data.url;

        // 直接重定向用户到微信授权页面
        window.location.href = wechatAuthUrl;
      } catch (error) {
        console.error('获取微信登录链接失败', error);
      }
    },

    async loginWithWechat() {
      try {
        // 向后端请求获取微信登录链接
        const res = await this.$http.get('wechat-login');
        this.wechatLoginUrl = res.data.url;
        // console.log('微信登录URL:', this.wechatLoginUrl);

        // 显示微信登录页面的小窗口
        // this.showWechatLoginPopup();

        // 打开新的小窗口显示微信登录页面
        this.openWechatLoginPopup();

        // 打开新的小窗口，并设置属性
        // const win = window.open(this.wechatLoginUrl,);

        // 监听来自微信登录页面的消息
        // window.addEventListener('message', (event) => {
        //   if (event.data && event.data.token) {
        //     // console.log('微信登录成功', event.data);

        //     win.close();
        //     // 关闭新窗口

        //     // 在这里处理 token 和 nickname，例如保存 token 并更新用户信息
        //     this.handleLoginSuccess(event.data.token, event.data.nickname);
        //   }
        // });

        // 定期检查新窗口是否关闭
        //  const interval = setInterval(() => {
        //   if (win.closed) {
        //     clearInterval(interval);
        //     // console.log('微信登录窗口已关闭');
        //   }
        // }, 1000);


        // 检查是否成功打开窗口
        // if (!win) {
        //   alert('无法打开窗口，请检查浏览器设置或者允许弹出窗口');
        // }

      } catch (error) {
        console.error('微信登录URL获取失败:', error);
      }
    },

    // checkWechatLoginSuccess() {
    //   const urlParams = new URLSearchParams(window.location.hash.substring(1));
    //   const token = urlParams.get('token');
    //   const nickname = urlParams.get('nickname');

    //   if (token && nickname) {
    //     console.log('微信登录成功', token, nickname);

    //     // 跳转到主页面
    //     this.$router.push('/');

    //     // 在这里处理 token 和 nickname，例如保存 token 并更新用户信息
    //     // this.handleLoginSuccess(token, nickname);
    //   }
    // },

    openWechatLoginPopup() {
      // 设置窗口的宽度和高度
      const width = 850;
      const height = 450;
      const top = 100;

      // 获取当前活动窗口的屏幕信息
      const currentWindow = window;
      const screenLeft = window.screenLeft || window.screenX;
      const screenWidth = window.screen.availWidth;

      // 计算窗口居中的位置
      let left;

      // 判断当前窗口在屏幕的位置，并选择合适的居中策略
      if (screenLeft < screenWidth / 2) {
        // 当前窗口在屏幕的左侧
        left = screenLeft + (currentWindow.outerWidth - width) / 2;
      } else {
        // 当前窗口在屏幕的右侧
        left = screenLeft + screenWidth / 2 - width / 2;
      }

      // 打开新的小窗口，并设置属性
      const win = window.open(this.wechatLoginUrl, '微信登录', `width=${width}, height=${height}, top=${top}, left=${left}`);

      // 监听来自微信登录页面的消息
      window.addEventListener('message', (event) => {
        if (event.data && event.data.token) {
          // console.log('微信登录成功', event.data);

          win.close();
          // 关闭新窗口

          // 在这里处理 token 和 nickname，例如保存 token 并更新用户信息
          this.handleLoginSuccess(event.data.token, event.data.nickname);
        }
      });

      // 定期检查新窗口是否关闭
      //  const interval = setInterval(() => {
      //   if (win.closed) {
      //     clearInterval(interval);
      //     // console.log('微信登录窗口已关闭');
      //   }
      // }, 1000);


      // 检查是否成功打开窗口
      if (!win) {
        alert('无法打开窗口，请检查浏览器设置或者允许弹出窗口');
      }
    },


    handleLoginSuccess(token, nickname) {
      // 保存 token 到本地存储或 Vuex
      localStorage.setItem('token', token);
      // 更新用户信息
      // this.$store.commit('setUser', { nickname });
      // 跳转到主页面
      this.$router.push('/');
      this.$message({
        type: 'success',
        message: '微信登录成功',
        offset: 100, // 这里设置消息提示框距离页面顶部 100 像素
        duration: 2000 // 设置消息提示显示时间为 5000 毫秒（5 秒）
      });
    },

    //   showWechatLoginPopup() {
    //   // 创建一个小窗口（iframe），显示微信登录页面
    //   const iframe = document.createElement('iframe');
    //   iframe.width = '300'; // 设置 iframe 宽度
    //   iframe.height = '400'; // 设置 iframe 高度
    //   iframe.src = this.wechatLoginUrl; // 设置 iframe 的 src 属性为微信登录页面的链接
    //   iframe.style.border = 'none'; // 去掉 iframe 的边框
    //   document.body.appendChild(iframe); // 将 iframe 添加到当前页面的 body 中
    // }

    // showWechatLoginPopup() {
    //   // 创建一个小窗口（iframe），显示微信登录页面
    //   const iframe = document.createElement('iframe');
    //   iframe.width = '300'; // 设置 iframe 宽度
    //   iframe.height = '400'; // 设置 iframe 高度
    //   iframe.src = this.wechatLoginUrl; // 设置 iframe 的 src 属性为微信登录页面的链接
    //   iframe.style.border = 'none'; // 去掉 iframe 的边框
    //   iframe.style.position = 'fixed'; // 使用 fixed 定位，让 iframe 固定在页面上
    //   iframe.style.top = '50%'; // 设置垂直居中
    //   iframe.style.left = '50%'; // 设置水平居中
    //   iframe.style.transform = 'translate(-50%, -50%)'; // 居中偏移
    //   document.body.appendChild(iframe); // 将 iframe 添加到当前页面的 body 中
    // }

    showWechatLoginPopup() {
      // 创建一个小窗口（iframe），显示微信登录页面
      const iframe = document.createElement('iframe');
      iframe.width = '300'; // 设置 iframe 宽度
      iframe.height = '400'; // 设置 iframe 高度
      iframe.src = this.wechatLoginUrl; // 设置 iframe 的 src 属性为微信登录页面的链接
      iframe.style.border = 'none'; // 去掉 iframe 的边框
      iframe.style.position = 'fixed'; // 使用 fixed 定位，让 iframe 固定在页面上
      iframe.style.top = '50%'; // 设置垂直居中
      iframe.style.left = '80%'; // 设置水平居中
      iframe.style.transform = 'translate(-50%, -50%)'; // 居中偏移
      document.body.appendChild(iframe); // 将 iframe 添加到当前页面的 body 中
    },

    checkIsMobile() {
      this.isMobile = window.innerWidth <= 768;
      // console.log(this.isMobile)
    },

  },

  created() {
    // this.checkWechatLoginSuccess();
    this.checkIsMobile();
    window.addEventListener('resize', this.checkIsMobile);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkIsMobile);
  }
};
</script>

<style>
.login-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(-225deg, #FAC4DB 0%, #B8DCFF 48%, #F2CEE8 100%);
  filter: blur(8px);
}

.login-card {
  position: relative;
  z-index: 1;
  width: 25rem;
  margin: 5rem auto;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
}

.login-header {
  display: flex;
  justify-content: center;
}

.login-title {
  display: flex;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.title-welcom {
  font-size: 23px;
  letter-spacing: 2px;
  font-weight: 500;
  margin-left: 10px;
  color: #666666;
  /* margin: 0px 10px; */
}

.login-form {
  margin-top: 20px;
}

.form-item {
  margin-bottom: 20px;
}

.form-item-login {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.login-button {
  /* width: 130px; */
  /* display: block;  */
  width: 180px;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 10px 25px -30px 25px;
  /* 设置按钮左右居中 */
}

.qrcode-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

@media (max-width: 768px) {

  .login-card {
    position: relative;
    z-index: 1;
    width: 20rem;
    margin: 5rem auto;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
  }

  .login-button {
    width: 180px;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
    /* display: block;  */
    margin: 10px 25px -30px 25px;
    /* 设置按钮左右居中 */
  }

}
</style>

<template>
  <div class="about">
    <h1>收到消息回复</h1>
    <el-form label-width="120px" @submit.native.prevent="save">
      <el-form-item label="回复内容" class="content-title">
        <el-input type="textarea" class="content" :autosize="{ minRows: 10, maxRows: 18 }" placeholder="请输入内容"
          v-model="model.content" size="large"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="success" class="save-btn" native-type="submit" style="width: 110px;">保存</el-button>
        <el-button type="danger" class="delete-btn" @click="confirmDelete" style="width: 110px;">删除回复</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>


<style>
/*回复内容*/
.content-title .el-form-item__label {
  text-align: center;
  vertical-align: middle;
  float: left;
  font-size: 17px;
  color: #000000;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}

.el-textarea__inner:hover {
  border-color: #6d85f3;
}

.el-textarea__inner {
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 100%;
  font-size: inherit;
  color: #333333;
  background-color: #FFF;
  background-image: none;
  border: 1px solid #DCDFE6;
  border-radius: 4px;
  font-family: Arial, sans-serif;
  transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
}

.content {
  margin: 5px;
  font-size: 16px;
  color: #9966ff;
}

.about .el-input__inner {
  color: #333333 !important;
}

.save-btn {
  margin: 3px 5px;
}

.delete-btn {
  margin: 3px 5px;
}
</style>


<script>
import { VueEditor } from "vue2-editor";
import { MessageBox } from 'element-ui';

export default {
  props: {
    id: {}
  },
  components: {
    VueEditor
  },
  data() {
    return {
      model: {
        name: '收到消息回复',
        content: '',
      },
      query: {
        seach_word: '收到消息回复',
      }
    }
  },
  methods: {
    async fetchRecord() {
      try {
        const res = await this.$http.get('rest/replymessages', { params: this.query });
        if (res.data.items.length > 0) {
          this.model = res.data.items[0];
          console.log('获取到的数据是:', this.model);
        } else {
          // 如果没有找到数据，初始化一个空的模型，以便用户输入
          this.model = { content: '' };
          console.log('没有找到数据，允许输入');
        }
      } catch (error) {
        console.error('获取数据失败：', error);
      }
    },
    // async fetchRecord() {
    //   try {
    //     const res = await this.$http.get('rest/replymessages');
    //     if (res.data.items && res.data.items.length > 0) {
    //       const data = res.data.items;
    //       const matchedItem = data.find(item => item.name === '收到消息回复');
    //       if (matchedItem) {
    //         this.model = matchedItem;
    //         console.log('实际数据是：', this.model);
    //       } else {
    //         console.error('未找到匹配的数据');
    //       }
    //     } else {
    //       console.error('未找到数据');
    //     }
    //   } catch (error) {
    //     console.error('获取数据失败：', error);
    //   }
    // },
    async save() {
      try {
        let res;
        if (this.model._id) {
          // 如果存在 _id，则说明已经有数据，直接发送更新请求
          res = await this.$http.put(`rest/replymessages/${this.model._id}`, {
            content: this.model.content
          });
        } else {
          // 如果不存在 _id，则说明没有数据，直接发送创建请求
          res = await this.$http.post('rest/replymessages', {
            name: '收到消息回复',
            content: this.model.content
          });
        }
        this.$message({
          type: 'success',
          message: '保存成功'
        });
      } catch (error) {
        console.error('保存失败：', error);
        this.$message({
          type: 'error',
          message: '保存失败，请重试'
        });
      }
      //this.fetchRecord();
    },
    async confirmDelete() {
      try {
        const confirmResult = await MessageBox.confirm('删除后，关注该公众号的用户将不再接收该回复，确定删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });
        if (confirmResult === 'confirm') {
          await this.deleteReply();
        }
      } catch (error) {
        console.error('删除确认失败：', error);
      }
    },
    async deleteReply() {
      try {
        if (this.model._id) {
          await this.$http.delete(`rest/replymessages/${this.model._id}`);
          this.$message({
            type: 'success',
            message: '删除成功'
          });
          // 清空模型
          this.model = { name: '收到消息回复', content: '' };
        } else {
          console.error('无法删除：缺少 ID');
        }
      } catch (error) {
        console.error('删除失败：', error);
        this.$message({
          type: 'error',
          message: '删除失败，请重试'
        });
      }
      //this.fetchRecord();
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fetchRecord(); // 加载数据
    });
  }
}
</script>

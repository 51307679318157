<template>
  <div class="about">
    <el-form ref="modelForm" :model="model" label-width="50px" class="form">
      <el-form-item label="" style="text-align: center;">
        <span class="name">{{ model.title }}</span>
      </el-form-item>
      <el-form-item label="" style="text-align: center;">
        <span class="artist">{{ model.artist }}</span>
      </el-form-item>
      <el-form-item label="">
        <span>专辑：{{ model.album }}</span>
      </el-form-item>
      <el-form-item label="">
        <div class="link">
          <span>链接：</span>
          <el-link type="primary" :href="model.link">点击打开</el-link>
          <el-tooltip content="复制链接" placement="top">
            <i class="el-icon-document-copy" @click="copyLink(model.link)" style="cursor: pointer; margin-left: 10px;">
            </i>
          </el-tooltip>
        </div>
      </el-form-item>
      <el-form-item label="">
        <span>密钥：{{ model.key }}</span>
      </el-form-item>
      <el-form-item label="">
        <span>来源：{{ model.source }}</span>
      </el-form-item>
      <el-form-item label="">
        <span>获取时间：{{ model.createdAt | formatDate }}</span>
      </el-form-item>
      <!-- 可选：增加一个返回按钮 -->
      <el-row class="button-group">
        <el-button @click="cancel" type="primary">返回</el-button>
      </el-row>
    </el-form>
  </div>
</template>

<style scoped>
.about {
  margin: 20px;
  display: flex;
  justify-content: center;
}

.form {
  max-width: 600px;
}

.name {
  text-align: center;
  font-size: 30px;
}

.artist {
  text-align: center;
  font-size: 15px;
}

.link {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
}

.copy {
  margin-left: 10px;
}


.button-group {
  text-align: center;
  margin-top: 20px;
}
</style>

<script>
import Clipboard from 'clipboard';

export default {
  props: {
    id: {}
  },
  data() {
    return {
      model: {
        unionid: '',
        title: '',
        artist: '',
        album: '',
        link: '',
        source: '',
        key: '',
        createdAt: '',
      }
    }
  },
  methods: {
    // async fetch() {
    //   const res = await this.$http.get(`listens/detail/${this.id}`)
    //   this.model = res.data
    // },

    async fetch() {
      try {
        const res = await this.$http.get(`iklistens/detail/${this.id}`);
        this.model = res.data;
      } catch (error) {
        if (error.response) {
          this.$router.push('/listens/notfound');
        } else {
          console.error(error);
        }
      }
    },
    async cancel() {
      this.$router.push('/listens/list');
    },

    copyLink(link) {
      const clipboard = new Clipboard('.el-icon-document-copy', {
        text: function() {
          return link;
        }
      });
      clipboard.on('success', () => {
        this.$message.success('链接已复制');
        clipboard.destroy();
      });
      clipboard.on('error', () => {
        this.$message.error('复制失败');
        clipboard.destroy();
      });
    }
    
  },
  created() {
    this.id && this.fetch()
  },
  filters: {
    formatDate(date) {
      if (!date) return '';
      const d = new Date(date);
      const year = d.getFullYear();
      const month = (`0${d.getMonth() + 1}`).slice(-2);
      const day = (`0${d.getDate()}`).slice(-2);
      const hours = (`0${d.getHours()}`).slice(-2);
      const minutes = (`0${d.getMinutes()}`).slice(-2);
      const seconds = (`0${d.getSeconds()}`).slice(-2);
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
  },
}
</script>

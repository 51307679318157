<template>
  <div class="about">
    <h1>被关注回复</h1>
    <el-form label-width="120px" @submit.native.prevent="save">
      <el-form-item label="回复内容" class="content-title">
        <el-input type="textarea" class="content" :autosize="{ minRows: 10, maxRows: 18 }" placeholder="请输入内容" v-model="model.content" size="large"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="success" class="save-btn" native-type="submit" style="width: 110px;">保存</el-button>
        <el-button type="danger" class="delete-btn" @click="confirmDelete" style="width: 110px;">删除回复</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>


<style>

.content{
  margin: 5px;
  font-size: 18px;
}

.save-btn{
  margin: 3px 5px;
}

.delete-btn{
  margin: 3px 5px;
}
</style>


<script>
import { VueEditor } from "vue2-editor";
import { MessageBox } from 'element-ui';

export default {
  props: {
    id: {}
  },
  components: {
    VueEditor
  },
  data() {
    return {
      model: {
        name: '被关注回复',
        content: '',
      },
      query:{
        seach_word:'被关注回复',
      },
    }
  },
  methods: {
    async fetchRecord() {
      try {
        const res = await this.$http.get('rest/replymessages', { params: this.query });
        if (res.data.items.length > 0) {
          this.model = res.data.items[0];
          console.log('获取到的数据是:', this.model);
        } else {
          // 如果没有找到数据，初始化一个空的模型，以便用户输入
          this.model = { content: '' };
          console.log('没有找到数据，允许输入');
        }
      } catch (error) {
        console.error('获取数据失败：', error);
      }
    },
    async save() {
      try {
        let res;
        if (this.model._id) {
          // 如果存在 _id，则说明已经有数据，直接发送更新请求
          res = await this.$http.put(`rest/replymessages/${this.model._id}`, {
            content: this.model.content
          });
        } else {
          // 如果不存在 _id，则说明没有数据，直接发送创建请求
          res = await this.$http.post('rest/replymessages', {
            name: '被关注回复',
            content: this.model.content
          });
        }
        this.$message({
          type: 'success',
          message: '保存成功'
        });
      } catch (error) {
        console.error('保存失败：', error);
        this.$message({
          type: 'error',
          message: '保存失败，请重试'
        });
      }
      this.fetchRecord();
    },
    async confirmDelete() {
      try {
        const  confirmResult = await MessageBox.confirm('删除后，关注该公众号的用户将不再接收该回复，确定删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });
        if (confirmResult) {
          await this.deleteReply();
        }
      } catch (error) {
        console.error('删除确认失败：', error);
      }
    },
    async deleteReply() {
      try {
        if (this.model._id) {
          await this.$http.delete(`rest/replymessages/${this.model._id}`);
          this.$message({
            type: 'success',
            message: '删除成功'
          });
          // 清空模型
          this.model = { name: '被关注回复', content: '' };
        } else {
          console.error('无法删除：缺少 ID');
        }
      } catch (error) {
        console.error('删除失败：', error);
        this.$message({
          type: 'error',
          message: '删除失败，请重试'
        });
      }
      this.fetchRecord();
    },
  },
  created() {
    this.fetchRecord(); // 加载数据
  }
}
</script>


<template>
  <div class="about">
    <h1>{{ id ? '编辑' : '添加' }}自动回复</h1>
    <el-form label-width="120px" @submit.native.prevent="save">
      <el-form-item class="biaoti" label="规则名称">
        <el-input v-model="model.name"></el-input>
      </el-form-item>
      <el-form-item class="biaoti" label="关键词回复" name="keywords">
        <el-button @click="model.keywords.push({ match: 'N' })"><i class="el-icon-plus"></i>添加关键词</el-button>
        <el-row type="flex" style="flex-wrap:wrap">
          <el-col :md="24" class="guanjianci" v-for="(item, i) in model.keywords" :key="i">
            <el-form-item class="keyword" label="关键词" label-width="55px">
              <el-select class="matchs" v-model="item.match">
                <el-option v-for="item of matchdata" :key="item._id" :label="item.name" :value="item.key"></el-option>
              </el-select>
              <el-input class="words" v-model="item.keyword" style="margin-left: 20" clearable></el-input>
              <el-button size="small" type="danger" @click="model.keywords.splice(i, 1)">删除</el-button>
            </el-form-item>
            <el-form-item>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item class="biaoti" label="回复内容">
        <el-input type="textarea" :autosize="{ minRows: 10, maxRows: 15 }" placeholder="请输入内容"
          v-model="model.content"></el-input>
        <!--<vue-editor v-model="model.content"></vue-editor>-->
      </el-form-item>

      <el-form-item>
        <el-button type="primary" native-type="submit">保存</el-button>
        <el-button type="danger" @click="cancel">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>


<style>
.about {
  margin: 0px 100px;
}

.guanjianci {
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.keyword {
  margin: 20px 0px;

}

.matchs {
  width: 120px;
  padding-left: 20px;
  padding-right: 10px;
}

.words {
  width: 500px;
  padding: 0em 2em;
}
</style>


<script>
import { VueEditor } from "vue2-editor";
import { Message } from 'element-ui';

export default {
  props: {
    id: {}
  },
  components: {
    VueEditor
  },
  data() {
    return {
      matchdata: [{
        name: '半匹配(N)',
        key: 'N',
      },
      {
        name: '全匹配(Y)',
        key: 'Y',
      }],
      model: {
        keywords: [],
      },
    }
  },
  methods: {
    async save() {
      // 检查规则名称和关键词是否为空
      if (!this.model.name.trim()) {
        this.$message({
          type: 'error',
          message: '规则名称不能为空'
        })
        return;
      }
      if (this.model.keywords.length === 0 || this.model.keywords.some(keyword => !keyword.keyword.trim())) {
        this.$message({
          type: 'error',
          message: '键词不能为空'
        })
        return;
      }

      let res
      if (this.id) {
        res = await this.$http.put(`rest/autoreplys/${this.id}`, this.model)
      } else {
        res = await this.$http.post('rest/autoreplys', this.model)
      }
      this.$router.push('/autoreplys/list')
      this.$message({
        type: 'success',
        message: '保存成功'
      })
    },
    async fetch() {
      const res = await this.$http.get(`rest/autoreplys/${this.id}`)
      this.model = res.data
    },
    async cancel() {
      this.$router.go(-1)
    },
  },
  created() {
    this.id && this.fetch()
  }
}

</script>

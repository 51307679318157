import axios from "axios";
import Vue from "vue";
import router from "./router";
import { apiBaseURL } from './config';

const http = axios.create({
    baseURL: apiBaseURL,
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json'
    }
});

// 用于标记错误是否已处理
let errorHandled = false;

// 请求拦截器
http.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    if(token){
        config.headers.Authorization = 'Bearer' + token
    }
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

// 响应拦截器
http.interceptors.response.use(res => {
    errorHandled = false;  // 重置标志
    return res 
}, err => {
    if (err.response && err.response.status === 401) {
        if (err.response.data.message && !errorHandled) {
            errorHandled = true;
            Vue.prototype.$message({
                type: 'error',
                message: err.response.data.message
            });
        }

        // 清除 token 和重定向到登录页面
        localStorage.clear();
        router.push('/login');
    } 
    else if (err.response && err.response.data.message && !errorHandled){
        // Vue.prototype.$message.error(err.response.data.message) 红色弹窗
        errorHandled = true;
        Vue.prototype.$message({
            type: 'error',
            message:err.response.data.message
        })
        // if (err.response.status === 401) {
        //     router.push('/login')
        // }
    }

    return Promise.reject(err)
})

export default http
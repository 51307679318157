<template>
  <div class="about">
    <h1>{{ model._id ? '编辑' : '添加' }}自动回复</h1>
    <el-form ref="modelForm" :model="model" label-width="120px" @submit.native.prevent="save">
      <el-form-item label="UnionID">
        <el-input v-model="model.unionid" clearable></el-input>
      </el-form-item>
      <el-form-item label="标题">
        <el-input v-model="model.title" clearable></el-input>
      </el-form-item>
      <el-form-item label="歌手">
        <el-input v-model="model.artist" clearable></el-input>
      </el-form-item>
      <el-form-item label="专辑">
        <el-input v-model="model.album" clearable></el-input>
      </el-form-item>
      <el-form-item label="链接">
        <el-input v-model="model.link" clearable></el-input>
      </el-form-item>
      <el-form-item label="密钥">
        <el-input v-model="model.key" clearable></el-input>
      </el-form-item>
      <el-form-item label="来源">
        <el-input v-model="model.source" clearable></el-input>
      </el-form-item>
      <el-form-item label="创建时间">
        <el-date-picker v-model="model.createdAt" type="datetime" placeholder="选择日期时间" clearable></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" native-type="submit">保存</el-button>
        <el-button type="danger" @click="cancel">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<style>
.about {
  margin: 0px 100px;
}

.guanjianci {
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.keyword {
  margin: 20px 0px;

}

.matchs {
  width: 120px;
  padding-left: 20px;
  padding-right: 10px;
}

.words {
  width: 500px;
  padding: 0em 2em;
}
</style>


<script>
import { VueEditor } from "vue2-editor";
import { Message } from 'element-ui';

export default {
  props: {
    id: {}
  },
  components: {
    VueEditor
  },
  data() {
    return {
      model: {
        unionid: '',
        title: '',
        artist: '',
        album: '',
        link: '',
        source: '',
        createdAt: new Date(),
      }
    }
  },
  methods: {
    async save() {
      let res
      if (this.id) {
        res = await this.$http.put(`rest/listen_lists/${this.id}`, this.model)
      } else {
        res = await this.$http.post('rest/listen_lists', this.model)
      }
      this.$router.push('/listens/list')
      this.$message({
        type: 'success',
        message: '保存成功'
      })
    },
    async fetch() {
      const res = await this.$http.get(`rest/listen_lists/${this.id}`)
      this.model = res.data
    },
    async cancel() {
      this.$router.go(-1)
    },
  },
  created() {
    this.id && this.fetch()
  }
}

</script>
